import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

// vuex是vue配套的公共数据管理工具
// 它可以把一些共享的数据，保存到vuex中，方便整个程序的任何组件直接获取修改我们的公共数据
// 【就相当于是一个数据的仓库】

export default new Vuex.Store({
  state: { // 相当于组件中的data，专门用来存储数据的
    car:[], //如果想在组件中访问car => this.$store.state.car访问
    collapse: false,
    isMobile: false,
    isDrawer: false
  },
  mutations: { // 相当于组件中的methods
    // 注意：1.如果想要操作store中的state值，只能通过mutations提供的方法操作对应的数据，不推荐在组件内部直接操作，因为万一导致数据错乱，不好定位问题
    //       2.如果组件想要调用mutations中的方法，只能使用this.$store.commit('方法名称',参数)，（可以传递0-1个参数）
    // 把后台请求回来的购物车数据复制给state中的car
    assignmentToCar(state,data){
      // 参数data为组件调用assignmentToCar方法时传入的参数
      state.car = data
    },
    // 加入购物车
    addToCar(state,data){
      // 查找这个商品之前有没有添加到购物车
      let index = '-1'
      state.car.forEach((val,idx)=>{
        // 这个商品之前被添加到购物车
        if(val.id == data.id){
          val.num += data.num;
          index = idx;
        }
      })
      // 这个商品之前没有被添加到购物车
      if(index == '-1'){
        state.car.push(data)
      }
    },
    // 从购物车中删除某个商品
    delToCar(state,index){
      state.car.splice(index,1)
    },
    collapse (state, data) {
      state.collapse = data
    },
    isMobile (state, data) {
      state.isMobile = data
    },
    toggleDrawer (state, data) {
      state.isDrawer = data
    },
  },
  getters:{
    // 1.getters中的方法和组件中的过滤器比较类似，因为过滤器和getters都没有修改原数据都是把原数据做了一层包装，提供给了调用者
    // 2.getters和computed比较像，只要state中的数据发生了变化，那么如果getters正好也引用了这个数据，那么就会立即触发getters的重新求值
    // 组件中访问 => this.$store.getters.totalGoodsNum

    // 计算添加到购物车中的所有商品总件数
    totalGoodsNum(state){
      let num = 0;
      state.car.forEach(val=>{
        num += val.num;
      })
      return num;
    },

    // 计算已选中的所有商品总件数
    totalSelectedGoodsNum(state){
      let num = 0;
      state.car.forEach(val=>{
        if(val.selected){
          num += val.num;
        }
      })
      return num;
    },

    // 计算已选中的所有商品总价格
    totalSelectedGoodsPrice(state){
      let price = 0;
        state.car.forEach(val=>{
          if(val.selected){
            price += (parseFloat(val.price) * val.num);
          }
        })
      return price;
    },
    collapse (state, getter) {
      return state.collapse
    },
    isMobile (state, getter) {
      return state.isMobile
    },
    isDrawer (state, getter) {
      return state.isDrawer
    },

  },
  actions: {
    // collapse ({ commit }, data) {
    //   return commit(`collapse`, data)
    // },
    // isMobile ({ commit }, data) {
    //   return commit(`isMobile`, data)
    // },
    // isDrawer ({ commit }, data) {
    //   return commit(`isDrawer`, data)
    // },
  },
  modules: {
  }
})
