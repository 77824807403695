import Vue from 'vue'

// 字体加粗
const fontWeight = Vue.directive('fontWeight', function (el) {
    el.style.fontWeight = 'bolder'
})

// 字体倾斜
const italic = Vue.directive('italic', function (el) {
  el.style.fontStyle = 'italic'
})

export default {
  fontWeight,
  italic
}