import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// 引入公共样式文件
import './assets/common.css'

// 完整引入elementUI
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)

// 引入下载好的阿里矢量字体图标
import './assets/iconfont/iconfont.css'
import ResponsivePlugin from './utils/device.js';
Vue.use(ResponsivePlugin);  
// 引入全屏插件
import screenfull from 'screenfull'
Vue.prototype.$screenfull = screenfull

// 引入引导页插件
import Driver from 'driver.js'
import 'driver.js/dist/driver.min.css'
Vue.prototype.$driver = Driver

// 引入animate.css
import animated from 'animate.css/animate.css'
Vue.use(animated)

// 图片预览插件 vue-photo-preview
import preview from 'vue-photo-preview'
import 'vue-photo-preview/dist/skin.css'
Vue.use(preview)

import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

// vue 编码但不渲染的标签 vue-fragment
// 主要解决递归菜单栏组件递归后不能折叠问题
import Fragment from 'vue-fragment'
Vue.use(Fragment.Plugin)

// 引入echarts
// const echarts = require('echarts');
// Vue.prototype.$echarts = echarts;

// 引入全局自定义过滤器
import * as filter from './assets/filter'
Object.keys(filter).forEach((key) => {
  Vue.filter(key, filter[key])
})

// 引入全局自定义指令
import * as directive from './assets/directive'
Object.keys(directive).forEach((key) => {
  Vue.directive(key, directive[key])
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
