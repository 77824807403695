// 全局时间过滤(时间戳修改为年月日时分秒)-精确到秒
let dateFormat = time => { 
  let date = new Date(time)
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  let hour = date.getHours();
  let minute = date.getMinutes();
  let second = date.getSeconds();
  month = month < 10 ? "0" + month : month;
  day = day < 10 ? "0" + day : day;
  hour = hour < 10 ? "0" + hour : hour;
  minute = minute < 10 ? "0" + minute : minute;
  second = second < 10 ? "0" + second : second;
  return year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
}

export {
  dateFormat
}
