import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/index', // 路由重定向
    component: () => import('../views/layout/index.vue'),
    children: [
      {
        path: '/index',
        name: 'Index',
        component: () => import('../views/Index/Index.vue'),
        meta: {
          title: '首页'
        }
      },
      {
        path: '/ranking',
        name: 'Ranking',
        component: () => import('../views/ranking/ranking.vue'),
        meta: {
          title: '网站排行榜'
        }
      },
      {
        path: '/about',
        name: 'About',
        component: () => import('../views/about/about.vue'),
        meta: {
          title: '介绍'
        }
      },

      {
        path: '/class',
        name: 'Class',
        component: () => import('../views/class/class.vue'),
        meta: {
          title: '所有类别'
        }
      },
      {
        path: '/new',
        name: 'New',
        component: () => import('../views/new/new.vue'),
        meta: {
          title: '最新收录'
        }
      },
      {
        path: '/details',
        name: 'Details',
        component: () => import('../views/details/details.vue'),
        meta: {
          title: '详情页'
        }
      },
      {
        path: '/gengduo',
        name: 'Gengduo',
        component: () => import('../views/gengduo/gengduo.vue'),
        meta: {
          title: '更多'
        }
      },
      {
        path: '/resou',
        name: 'ReSou',
        component: () => import('../views/resou/resou.vue'),
        meta: {
          title: '热搜/搜索'
        }
      },
      {
        path: '/zixun',
        name: 'Zixun',
        component: () => import('../views/zixun/zixun.vue'),
        meta: {
          title: '资讯'
        }
      },
      {
        path: '/zhuye',
        name: 'Zhuye',
        component: () => import('../views/zhuye/zhuye.vue'),
        meta: {
          title: '主页'
        }
      },
      {
        path: '/flooding',
        name: 'flooding',
        component: () => import('../views/flooding/flooding.vue'),
        meta: {
          title: '资讯分类'
        }
      },
      {
        path: '/globalSearch',
        name: 'globalSearch',
        component: () => import('../views/globalSearch/globalSearch.vue'),
        meta: {
          title: '全局搜索'
        }
      }
    ]
  }
  // {
  //   path: '/home',
  //   name: 'Home',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
  // }
]

const router = new VueRouter({
  routes
})

export default router
