// responsivePlugin.js  
const ResponsivePlugin = {  
  install(Vue, options) {  
    // 定义一个全局混入，用于在组件中自动添加响应屏幕尺寸变化的功能  
    Vue.mixin({  
      data() {  
        return {  
          deviceType: 'unknown' // 初始设备类型  
        };  
      },  
      created() {  
        this.updateDeviceType();  
        // 监听窗口大小变化  
        window.addEventListener('resize', this.updateDeviceType);  
      },  
      beforeDestroy() {  
        // 移除事件监听器，防止内存泄漏  
        window.removeEventListener('resize', this.updateDeviceType);  
      },  
      methods: {  
        updateDeviceType() {  
          const mqMobile = window.matchMedia('(max-width: 767px)');  
          const mqTablet = window.matchMedia('(min-width: 768px) and (max-width: 991px)');  
          const mqDesktop = window.matchMedia('(min-width: 1200px)');  
  
          if (mqMobile.matches) {  
            this.deviceType = 'phone';  
          } else if (mqTablet.matches) {  
            this.deviceType = '平板端';  
          } else if (mqDesktop.matches) {  
            this.deviceType = 'PC';  
          } else {  
            this.deviceType = '其他';  
          }  
        }  
      }  
    });  
  
    // 如果你想提供一个全局方法来获取当前设备类型（尽管在组件中可以直接访问），也可以这样做：  
    // Vue.prototype.$getDeviceType = function() {  
    //   const mqMobile = ...; // 重复的逻辑  
    //   // ... 根据媒体查询返回设备类型  
    // };  
    // 但由于我们已经在mixin中处理了，所以这一步是可选的。  
  }  
};  
  
export default ResponsivePlugin;